import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/publicidad.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const ASHealth = props => (
  <Layout
    title="AS Health"
    description="Nace con el objetivo de implementar un programa médico integral para promover la salud física y psicosocial en entornos laborales que garanticen una mejor calidad de vida, un mejor rendimiento y productividad laboral dentro de las Micro y Pequeñas Empresas en México."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.as-health.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.as-health.content.p1" />
        </p>
        <h3>
          <FormattedMessage id="solutions.as-health.content.subtitle1" />
        </h3>
        <ul>
          {_.map(_.times(12, String), (value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.as-health.content.list1.item${value}`}
              />
            </li>
          ))}
        </ul>

        <ContractButtonSmall to="/contacto/" small>
          <FormattedMessage id="element.button_hire" />
        </ContractButtonSmall>
      </div>
    </Section>

    <FooterContact />
  </Layout>
)

export default ASHealth
